import { useNavigate } from "react-router-dom";
import DynamicTable, {
  textStyle,
} from "components/UI/phase-3/dynamicTable/DynamicTable";
import { Box, Chip, Icon, TableCell, TableRow } from "@mui/material";
import file from "images/FileVerticle.png";
import MoreVert from "@mui/icons-material/MoreVert";

const dummyData = [
  {
    name: "Materiality Mapping",
    frequency: "materialityMapping",
    yearOfAssessment: "24 March 2024",
    status: "active",
    action: "",
  },
];
function ComplianceTask() {
  const navigate = useNavigate();
  return (
    <>
      <DynamicTable
        headings={[
          "Name Of The Report",
          "Frequency",
          "Year Of Assessment",
          "Status",
          "Action",
        ]}
      >
        {dummyData.map((rowData, rowIndex) => (
          <TableRow
            key={rowIndex}
            //   className={classes.tableRowHover}
            onClick={() => {
              // dispatch(setSelectedCorporate(rowData));
              navigate(
                `/task/ESG_Compliance/${rowData.name?.replaceAll(" ", "_")}`
              );
            }}
            sx={{
              borderBottom: "1px solid #EBF1F8",
              cursor: "pointer",
            }}
            hover
          >
            <TableCell style={textStyle("main")}>
              <Box display={"flex"} alignItems={"center"} sx={{ gap: 2 }}>
                <Icon
                  component={"img"}
                  fontSize="small"
                  src={file}
                  color="primary"
                ></Icon>
                {rowData.name}
              </Box>
            </TableCell>
            <TableCell style={textStyle()}>{rowData.frequency}</TableCell>
            <TableCell style={textStyle()}>
              {rowData.yearOfAssessment}
            </TableCell>

            <TableCell style={textStyle()}>
              <Chip
                sx={(theme) => ({
                  backgroundColor:
                    rowData.status === "active"
                      ? theme.palette.primary.lighter
                      : theme.palette.secondary.lighter,
                  padding: "6px 10px",
                  fontSize: "12px",
                  color:
                    rowData.status === "active"
                      ? theme.palette.primary.dark
                      : theme.palette.secondary.dark,
                  borderRadius: "80px",
                })}
                label={rowData.status}
              />
            </TableCell>
            <TableCell style={textStyle()}>
              <MoreVert />
            </TableCell>
          </TableRow>
        ))}
      </DynamicTable>
    </>
  );
}

export default ComplianceTask;
