import React, { useEffect } from "react";
import {
  useRoutes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import OnboardingTour from "../../screen/onboardinTour/onboardinTour";

// import SurveyListingCont from '../../../containers/surveyListing/surveyListingCont';
// import ReportListingCont from '../../../containers/reportListing/reportListingCont';
// import ESGReportListingCont from '../../../containers/esrReportListing/esgReportListingCont';
// import SingleESGReportCont from '../../../containers/singleESGReport/sigleESGReportCont';
// import MetricReport from '../../screen/singleESGReport/metricReport';
// import SustainabilityReportCont from '../../../containers/SustainabilityReportMain/SustainabilityReportMainCont';
// import DashboardCont from '../../../containers/dashboard/dashboardCont';
import StaticdashboardScreen from "../../screen/dashboardStatic/StaticdashboardScreen";
import DataHubCont from "../../../containers/dataHub/dataHubCont";
import DataHubSurveyCont from "../../../containers/dataHub/dataHubSurveyCont";
import DataHubSelectedSurveyCont from "../../../containers/dataHub/dataHubSelectedSurveyCont";
import GeneralSettingsCont from "../../../containers/generalSettings/generalSettingsCont";
// import EsgReport from '../../widgets/esgReport';
import FAQ from "../../widgets/policies/faq";
import TnC from "../../widgets/policies/T&C";
import Privacy from "../../widgets/policies/privacy";
import MainLayoutStepper from "components/layout/stepperLayout/mainLayout";
import MainLayoutSection from "components/layout/twoSectionLayout/MainLayoutSection";
import LeftSectionLayout from "components/layout/twoSectionLayout/LeftSectionLayout";
import RightSectionLayout from "components/layout/twoSectionLayout/RightSectionLayout";
import EsgMaturity from "components/screen/phase-3/esgMaturity/EsgMaturity";
import OrgDetailsCont from "containers/phase-3/OrgDetails/OrgDetailsCont";
import MaterialityMappingLayout from "containers/phase-3/materialtyMapping/MaterialityMappingCont";
import MaterialityMapping from "components/screen/phase-3/materialityMapping/MaterialityMappingListing";
import MaterialityMappingSteps from "components/screen/phase-3/materialityMapping/MaterialityMappingSteps";
import EsgStrategyAndPlanning from "components/screen/phase-3/esgStrategyAndPlanning/EsgStrategyPlanningSteps";
import StrategyPlanningLayout from "containers/phase-3/startegyPlanning/StrategyPlanningCont";
import StrategyPlanningListing from "components/screen/phase-3/esgStrategyAndPlanning/EsgStrategyPlanningListing";
import StrategyPlanningSteps from "components/screen/phase-3/esgStrategyAndPlanning/EsgStrategyPlanningSteps";
import EsgComplianceLayout from "containers/phase-3/esgCompliance/esgComplianceCont";
import EsgComplianceListing from "components/screen/phase-3/esgCompliance/EsgComplinaceListing";
import EsgComplianceSteps from "components/screen/phase-3/esgCompliance/EsgComplianceSteps";
import SurveyLayout from "containers/phase-3/survey/SurveyCont";
import SurveyListing from "components/screen/phase-3/survey/SurveyListing";
import SurveyTabs from "components/screen/phase-3/survey/SurveyTabs";
import SupplierLayout from "containers/phase-3/supplier/SupplierCont";
import SupplierListing from "components/screen/phase-3/supplier/SupplierListing";
import SupplierTabs from "components/screen/phase-3/supplier/SupplierTabs";
import GetStartedCont from "containers/phase-3/getStartedCont/GetStartedCont";
import TaskLayout from "containers/phase-3/task/TaskCont";
import TaskListing from "components/screen/phase-3/task/TaskMain/TaskListing";
import { NoDataComponent } from "components/widgets/phase-3/esgMaturity/shareSurvey/NoData";
import ComplianceTask from "components/screen/phase-3/task/TaskSecondlayer/ComplianceTask";
import ComplianceMain from "components/screen/phase-3/task/TaskThirdLayer/ComplianceMain";
import ESGMaturityTask from "components/screen/phase-3/task/TaskSecondlayer/ESGMaturityTask";
import MaterialityMappingTask from "components/screen/phase-3/task/TaskSecondlayer/MaterialityMappingTask";
import MaterialityMain from "components/screen/phase-3/task/TaskThirdLayer/MaterialityMain";
import EsgStrategyPlanningTask from "components/screen/phase-3/task/TaskSecondlayer/EsgStrategyPlanningTask";
import EsgStrategyPlanningMain from "components/screen/phase-3/task/TaskThirdLayer/EsgStrategyPlanningMain";

const AuthenticatedRoutes = ({ login, userType, ...props }) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Define routes configuration
  const routes = [
    {
      path: "/get_started",
      element: <GetStartedCont />,
      userTypes: ["ADMIN", "GENERAL"],
    },
    {
      path: "/onboarding_tour",
      element: <OnboardingTour />,
      userTypes: ["ADMIN", "GENERAL"],
    },
    {
      path: "/dashboard",
      element: <StaticdashboardScreen />,
      userTypes: ["ADMIN", "GENERAL"],
    },
    {
      path: "/organisational_structure",
      element: <OrgDetailsCont />,
      userTypes: ["ADMIN", "GENERAL"],
    },
    {
      path: "/esg_maturity",
      element: <EsgMaturity />,
      userTypes: ["ADMIN", "GENERAL"],
    },
    {
      path: "/materiality_mapping",
      element: <MaterialityMappingLayout />,
      userTypes: ["ADMIN", "GENERAL"],
      children: [
        { element: <MaterialityMapping />, index: true },
        { path: ":materialityName", element: <MaterialityMappingSteps /> },
      ],
    },
    {
      path: "/esg_strategy",
      userTypes: ["ADMIN", "GENERAL"],
      element: <StrategyPlanningLayout />,
      children: [
        { element: <StrategyPlanningListing />, index: true },
        { path: ":strategyName", element: <StrategyPlanningSteps /> },
      ],
    },
    {
      path: "/esg_compliance",
      userTypes: ["ADMIN", "GENERAL"],
      element: <EsgComplianceLayout />,
      children: [
        { element: <EsgComplianceListing />, index: true },
        { path: ":frameworkName", element: <EsgComplianceSteps /> },
      ],
    },
    {
      path: "/supplier",
      userTypes: ["ADMIN", "GENERAL"],
      element: <SupplierLayout />,
      children: [
        { element: <SupplierListing />, index: true },
        { path: ":assessmentName", element: <SupplierTabs /> },
      ],
    },
    {
      path: "/task",
      userTypes: ["ADMIN", "GENERAL"],
      element: <TaskLayout />,
      children: [
        { element: <TaskListing />, index: true },
        {
          path: "Materiality_Mapping",
          children: [
            {
              index: true,
              element: <MaterialityMappingTask />, // Render ComplianceTask only at /task/Materiality_Mapping
            },
            {
              path: ":MaterialityAssessmentName",
              element: <MaterialityMain />,
            },
          ],
        },
        {
          path: "ESG_Compliance",
          children: [
            {
              index: true,
              element: <ComplianceTask />, // Render ComplianceTask only at /task/ESG_Compliance
            },
            {
              path: ":complianceName",
              element: <ComplianceMain />,
            },
          ],
        },
        {
          path: "Survey",
          element: <NoDataComponent mainTitle={"No Survey Task Assigned"} />,
        },
        {
          path: "ESG_Strategy",
          children: [
            {
              index: true,
              element: <EsgStrategyPlanningTask />, // Render ComplianceTask only at /task/ESG_Compliance
            },
            {
              path: ":strategyName",
              element: <EsgStrategyPlanningMain />,
            },
          ],
        },
        {
          path: "ESG_Maturity",
          element: <ESGMaturityTask />,
        },
      ],
    },
    {
      path: "survey",
      userTypes: ["ADMIN", "GENERAL"],
      element: <SurveyLayout />,
      children: [
        { element: <SurveyListing />, index: true },
        { path: ":surveyName", element: <SurveyTabs /> },
      ],
    },
    {
      path: "/data_hub/sheets_import/:page",
      element: <DataHubSurveyCont />,
      userTypes: ["ADMIN", "GENERAL"],
    },
    {
      path: "/data_hub/survey/selected_survey",
      element: <DataHubSelectedSurveyCont />,
      userTypes: ["ADMIN", "GENERAL"],
    },
    {
      path: "/data_hub/:page",
      element: <DataHubCont />,
      userTypes: ["ADMIN", "GENERAL"],
    },
    {
      path: "/general_settings",
      element: <GeneralSettingsCont />,
      userTypes: ["ADMIN", "GENERAL"],
    },
    { path: "/faq", element: <FAQ />, userTypes: ["ADMIN", "GENERAL"] },
    {
      path: "/privacy_policy",
      element: <Privacy />,
      userTypes: ["ADMIN", "GENERAL"],
    },
    { path: "/tnc", element: <TnC />, userTypes: ["ADMIN", "GENERAL"] },
    {
      path: "*",
      element: <Navigate to="/get_started" />,
      userTypes: ["ADMIN", "GENERAL"],
    }, // Default route for all user types
  ];

  // Filter routes based on userType
  const filteredRoutes = routes.filter((route) =>
    route.userTypes.includes(userType)
  );

  // useEffect(() => {
  //   // Handle redirection based on subscription status and user type
  //   const isGeneralSettings = location.pathname === "/general_settings";

  //   if (!login.isSubscriptionActive && !isGeneralSettings) {
  //     navigate("/general_settings");
  //   }
  // }, [login.isSubscriptionActive, userType, location.pathname, navigate]);

  // Use the routes
  const element = useRoutes(filteredRoutes);

  return element;
};

export default AuthenticatedRoutes;

// old routes
// const routes = [
//   { path: "/get_started", element: <GetStartedCont />, userTypes: ["ADMIN", "GENERAL"] },
//   { path: "/onboarding_tour", element: <OnboardingTour />, userTypes: ["ADMIN", "GENERAL"] },
//   { path: "/survey_listing", element: <SurveyListingCont />, userTypes: ["GENERAL"] },
//   { path: "/report_listing", element: <ReportListingCont />, userTypes: ["ADMIN", "GENERAL"] },
//   { path: "/report_listing/esg_report", element: <ESGReportListingCont />, userTypes: ["ADMIN", "GENERAL"] },
//   { path: "/report_listing/esg_report/single_report", element: <SingleESGReportCont />, userTypes: ["ADMIN", "GENERAL"] },
//   { path: "/report_listing/esg_report/metric_report", element: <MetricReport />, userTypes: ["ADMIN", "GENERAL"] },
//   { path: "/sustainability_report", element: <SustainabilityReportCont />, userTypes: ["GENERAL"] },
//   { path: "/dashboard", element: <DashboardCont />, userTypes: ["ADMIN", "GENERAL"] },
//   { path: "/dashboard_static", element: <StaticdashboardScreen />, userTypes: ["ADMIN", "GENERAL"] },
//   { path: "/data_hub/sheets_import/:page", element: <DataHubSurveyCont />, userTypes: ["ADMIN", "GENERAL"] },
//   { path: "/data_hub/survey/selected_survey", element: <DataHubSelectedSurveyCont />, userTypes: ["ADMIN", "GENERAL"] },
//   { path: "/data_hub/:page", element: <DataHubCont />, userTypes: ["ADMIN", "GENERAL"] },
//   { path: "/general_settings", element: <GeneralSettingsCont />, userTypes: ["ADMIN", "GENERAL"] },
//   { path: "/esg_report", element: <EsgReport />, userTypes: ["ADMIN"] },
//   { path: "/faq", element: <FAQ />, userTypes: ["ADMIN", "GENERAL"] },
//   { path: "/privacy_policy", element: <Privacy />, userTypes: ["ADMIN", "GENERAL"] },
//   { path: "/tnc", element: <TnC />, userTypes: ["ADMIN", "GENERAL"] },
//   { path: "*", element: <Navigate to="/get_started" />, userTypes: ["ADMIN", "GENERAL"] }, // Default route for all user types
// ];
