import apiBolerplate from "utils/_helpers/apiBolerplate";

import {
  SET_ADD_CHAT,
  SET_CHAT_GROUP,
  SET_CHATS_CONVERSATION,
  SET_FOLLOW_UP_QUESTION,
  SET_SELECTED_GPT_CHAT,
} from "constants/phase-3/gptWise/gptWiseConst";
import { set_snack_bar } from "actions/snackbar/snackbar_action";
import { startChatTimer } from "../chatTimers/chatTimersActions";
import { chatRefreshTimer, fetchRetryDelay } from "config";
import { socket } from "socket";

// ----------------- Payloads -----------------
export function setSelectedGPTChat(payload) {
  return {
    type: SET_SELECTED_GPT_CHAT,
    payload: payload,
  };
}

export const setChatGroups = (payload) => {
  return {
    type: SET_CHAT_GROUP,
    payload,
  };
};

export const setChatConversations = (payload) => {
  return {
    type: SET_CHATS_CONVERSATION,
    payload,
  };
};

export const setAddChat = (payload) => {
  return {
    type: SET_ADD_CHAT,
    payload,
  };
};

export const setFollowUpQues = (payload) => {
  return {
    type: SET_FOLLOW_UP_QUESTION,
    payload,
  };
};

// ----------------- Actions -----------------

// step-1: create question + emit start_chat_session
export const getGptAnswer = ({
  question,
  selectedChat, // conatins conversation id as chat_id
}) => {
  return async (dispatch, getState) => {
    if (selectedChat?.chat_id === "new-chat") {
      selectedChat = { ...selectedChat, chat_id: "" };
    }

    const { chats } = getState().gptWise;
    const { user_id } = getState().user;

    // getting new question_id from from backend
    const newQuesObj = await dispatch(
      createNewQues(question, selectedChat?.chat_id)
    );

    if (!newQuesObj?.chat_id) {
      dispatch(set_snack_bar(true, "Error while adding chat"));
      return;
    }

    const question_id = newQuesObj?.chat_id;

    // check if its a new chat
    const isNewChat = !selectedChat?.chat_id;

    if (isNewChat) {
      selectedChat = { type: "old", chat_id: newQuesObj?.chat_group_id };

      // refresh chat group list
      await dispatch(viewChatGroups());

      // select new chat from list
      dispatch(setSelectedGPTChat(selectedChat));
    }

    // create a new chat object to be added to current list of chats for this convo
    const chatObj = {
      _id: question_id,
      question,
      isLoading: true,
      thinking: "",
      answer: "",
    };

    const setConvoTimeout = isNewChat ? 500 : 0;

    // adding created chatObj to list of chats for current convo
    setTimeout(async () => {
      await dispatch(setChatConversations([...chats, chatObj]));
    }, setConvoTimeout);

    // Start a timer that fetches the answer from MongoDB after 1 minute if no response
    dispatch(
      startChatTimer(question_id, chatRefreshTimer, () =>
        fetchAnswerFromMongo(question_id)
      )
    );

    // --------- Socket work starts here ---------

    // getting chat history to be sent to socket server
    const chatHistory = await dispatch(getChatHistory(selectedChat?.chat_id));

    // selecting socket to use based on if comapny name is present
    const socketToUse = socket;

    // creating a socketQuesObj to be sent to socket
    const socketQuesObj = {
      question_id: question_id,
      question: question,
      isin: undefined,
      chatHistory: chatHistory,
      user_id: user_id,
      chat_id: selectedChat?.chat_id,
    };

    console.log("Question is: ", socketQuesObj);

    // emitting start_chat_session for socket
    socketToUse.emit("start_chat_session", socketQuesObj);
    // ---------- Socket work ends here ----------
  };
};

// step-2: function to create question obj
export const createNewQues = (question, chat_group_id) => {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/python_apis/add_chat",
      bodyData: {
        question,
        company_id: undefined,
        chat_group_id,
        is_company_based: false,
        isin: "",
      },
      hideLoader: true,
      hideMsg: true,
    });

    return data?.result;
  };
};

// step-3: get list of updated chats
export const viewChatGroups = () => {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/python_apis/get_chat_groups",
      hideLoader: true,
      hideMsg: true,
      callback: (data) => {
        // final actions
        dispatch(setChatGroups(data?.result?.reverse()));
      },
    });
  };
};

// step-4: function to fetch answer from db with question_id
export const fetchAnswerFromMongo = (question_id) => {
  return async (dispatch) => {
    const data = {
      question_id,
      is_company_based: false,
    };

    try {
      // Fetch answer with retry
      const result = await fetchAnswerWithRetry(data);

      dispatch(
        setAddChat({
          _id: question_id,
          isLoading: false,
          thinking: "",
          answer: result.answer, // Access the answer object directly
        })
      );
    } catch (error) {
      dispatch(
        setAddChat({
          _id: question_id,
          isLoading: false,
          thinking: "",
          answer: "",
        })
      );
      console.error("Error fetching answer:", error);
    }
  };
};

// step-5b: Helper function to wait for a specified number of milliseconds
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// step-5a: Function to attempt fetching answer with retry logic
const fetchAnswerWithRetry = async (
  fetchData,
  attempts = 3,
  delayMs = fetchRetryDelay
) => {
  return async (dispatch) => {
    try {
      const data = await apiBolerplate({
        dispatch,
        url: "/python_apis/add_chat",
        bodyData: {
          ...fetchData,
        },
        hideLoader: true,
        hideMsg: true,
      });

      if (data?.status && data?.result?.answer) {
        return data?.result;
      } else {
        throw new Error("Answer not found");
      }
    } catch (error) {
      if (attempts >= 1) {
        console.log(`Attempt failed, retrying in ${delayMs / 1000} seconds...`);
        await delay(delayMs);
        return fetchAnswerWithRetry(fetchData, attempts - 1, delayMs); // Retry
      } else {
        throw new Error("Failed to fetch answer after multiple attempts");
      }
    }
  };
};

// step-6: fetch convo history to send to socket
const getChatHistory = (chat_group_id) => {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/python_apis/get_chat_history_for_python",
      bodyData: {
        is_company_based: false,
        chat_group_id: chat_group_id || "",
        company_id: "",
      },
      hideLoader: true,
      hideMsg: true,
    });

    return data?.result;
  };
};

// this action is for updating the correct thinking state
export const updateThinkingForConvo = (question_id, thinkingMsg) => {
  return (dispatch) => {
    // this will update the chat after finding the correct questionObj to update
    dispatch(
      setAddChat({
        _id: question_id,
        isLoading: true,
        thinking: thinkingMsg,
        answer: "",
      })
    );
  };
};

// this action is for updating the correct answer for question state
export const updateAnswerForConvo = (question_id, answerObj) => {
  return (dispatch) => {
    // this will update the chat after finding the correct questionObj to update
    dispatch(
      setAddChat({
        _id: question_id,
        isLoading: false,
        thinking: "",
        answer: answerObj,
      })
    );
  };
};

// get current chat conversations
export const getGptChatHistory = (chat_id) => {
  return async (dispatch) => {
    await apiBolerplate({
      dispatch,
      url: "/python_apis/get_chat_history",
      bodyData: {
        chat_group_id: chat_id,
        is_company_based: false,
      },
      hideLoader: true,
      hideMsg: true,
      callback: (data) => {
        dispatch(setChatConversations(data?.result));
      },
    });
  };
};

// delete a chat from list
export const deleteChatGroup = ({ chat_id }) => {
  return async (dispatch, getState) => {
    await apiBolerplate({
      dispatch,
      url: "/python_apis/delete_chat_group",
      bodyData: {
        chat_id: chat_id,
      },
      callback: (data) => {
        // refresh chat list
        dispatch(viewChatGroups());

        const { selectedChat } = getState().gptWise;

        if (selectedChat?.chat_id === chat_id) {
          dispatch(setSelectedGPTChat({ type: "new", chat_id: "new-chat" }));
        }
      },
    });
  };
};

// update chat name
export const updateChatName = ({ chat_id, group_name }) => {
  return async (dispatch) => {
    await apiBolerplate({
      dispatch,
      url: "/python_apis/update_chat_group_name",
      bodyData: {
        chat_group_id: chat_id,
        group_name,
      },
      callback: (data) => {
        // refresh chat list
        dispatch(viewChatGroups());
      },
    });
  };
};
