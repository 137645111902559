import { SET_ALL_FRAME_WORK_LIST } from "constants/phase-3/frameWork/frameWorkConstant";
import apiBolerplate from "utils/_helpers/apiBolerplate";

// ----------------- Payloads -----------------
export function setAllFrameWorkList(payload) {
  return {
    type: SET_ALL_FRAME_WORK_LIST,
    payload: payload,
  };
}

// ----------------- Actions -----------------


export function getAllFrameWorkList() {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/frameworks/get_all_frameworks",
      method: "get",
      callback: (data) => {
        // final actions
        dispatch(setAllFrameWorkList(data?.result));
      },
    });
  };
}
