import React, { useEffect, useState } from "react";

import RadarChart from "../../../../UI/maturityMatrix/RadarChart";
import { AppBar, Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

const esgBreakdownStyles = makeStyles(() => ({
  AppBar: { boxShadow: "none" },
  tab: {
    fontSize: 12,
    minWidth: "85px",
  },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
}));

function EsgBreakdown(props) {
  const classes = esgBreakdownStyles();
  const { esg_maturity_score } = useSelector((state) => state.esgMaturity);

  const [ChartTabNo, setChartTabNo] = useState(0);

  function TabPanel(props) {
    const { children, value, index, category } = props;
    return (
      <div>
        {value === index && (
          <Box>
            {category === "Data unavailable" ? (
              <Typography variant="h6">Data unavailable</Typography>
            ) : (
              children
            )}
          </Box>
        )}
      </div>
    );
  }

  const [chartEsgData, setChartEsgData] = useState([]);
  const [allData, setAllData] = useState({});

  useEffect(() => {
    charting();
  }, []);

  const charting = () => {
    // Extract categories from esg_maturity_score
    const categories = esg_maturity_score?.categories || [];

    const findCategory = (categoryName) => {
      return categories.find((cat) => cat.category === categoryName);
    };

    const environmentCategory = findCategory("Environment");
    const socialCategory = findCategory("Social Capital");
    const governanceCategory = findCategory("Leadership & Governance");
    const businessCategory = findCategory("Business Model & Innovation");
    const humanCategory = findCategory("Human Capital");

    const chartData = [
      environmentCategory
        ? environmentCategory.fraction_value
        : "Data unavailable",
      socialCategory ? socialCategory.fraction_value : "Data unavailable",
      governanceCategory
        ? governanceCategory.fraction_value
        : "Data unavailable",
      businessCategory ? businessCategory.fraction_value : "Data unavailable",
      humanCategory ? humanCategory.fraction_value : "Data unavailable",
    ];

    setChartEsgData(chartData);

    setAllData({
      ...allData,
      environmentObj: environmentCategory || "Data unavailable",
      socialObj: socialCategory || "Data unavailable",
      governanceObj: governanceCategory || "Data unavailable",
      businessObj: businessCategory || "Data unavailable",
      humanObj: humanCategory || "Data unavailable",
    });
  };

  return (
    <Box>
      <AppBar position="static" color="inherit" className={classes.AppBar}>
        <Tabs
          value={ChartTabNo}
          variant="scrollable"
          onChange={(e, i) => {
            setChartTabNo(i);
          }}
        >
          <Tab disableRipple className={classes.tab} label="Over All" />
          <Tab disableRipple className={classes.tab} label="Environment" />
          <Tab disableRipple className={classes.tab} label="Social Capital" />
          <Tab disableRipple className={classes.tab} label="Governance" />
          <Tab
            disableRipple
            className={classes.tab}
            label="Business Model & Innovation"
          />
          <Tab disableRipple className={classes.tab} label="Human Capital" />
        </Tabs>
      </AppBar>

      <TabPanel value={ChartTabNo} index={0} category="Overall">
        {/* Overall Chart Logic */}
        <Grid>
          {/* <Typography>{`${
            currentMaturityData?.organisation_name
              ? currentMaturityData?.organisation_name
              : basicInfo?.companyName
          } Overall`}</Typography> */}
          <RadarChart
            {...props}
            allData={allData}
            chartEsgData={chartEsgData}
            ChartTabNo={ChartTabNo}
          />
        </Grid>
      </TabPanel>
      <TabPanel value={ChartTabNo} index={1} category={allData.environmentObj}>
        {/* Environment Chart Logic */}
        <Typography>{`Environment`}</Typography>
        <RadarChart
          {...props}
          allData={allData}
          chartEsgData={chartEsgData}
          ChartTabNo={ChartTabNo}
        />
      </TabPanel>
      <TabPanel value={ChartTabNo} index={2} category={allData.socialObj}>
        {/* Social Capital Chart Logic */}
        <Typography>{`Social Capital`}</Typography>
        <RadarChart
          {...props}
          allData={allData}
          chartEsgData={chartEsgData}
          ChartTabNo={ChartTabNo}
        />
      </TabPanel>
      <TabPanel value={ChartTabNo} index={3} category={allData.governanceObj}>
        {/* Governance Chart Logic */}
        <Typography>{`Governance`}</Typography>
        <RadarChart
          {...props}
          allData={allData}
          chartEsgData={chartEsgData}
          ChartTabNo={ChartTabNo}
        />
      </TabPanel>
      <TabPanel value={ChartTabNo} index={4} category={allData.businessObj}>
        {/* Business Model & Innovation Chart Logic */}
        <Typography>{`Business`}</Typography>
        <RadarChart
          {...props}
          allData={allData}
          chartEsgData={chartEsgData}
          ChartTabNo={ChartTabNo}
        />
      </TabPanel>
      <TabPanel value={ChartTabNo} index={5} category={allData.humanObj}>
        {/* Human Capital Chart Logic */}
        <Typography>{`Human Capital`}</Typography>
        <RadarChart
          {...props}
          allData={allData}
          chartEsgData={chartEsgData}
          ChartTabNo={ChartTabNo}
        />
      </TabPanel>
    </Box>
  );
}

export default EsgBreakdown;
