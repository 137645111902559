import { getAllFrameWorkList } from "actions/phase-3/framework/frameWorkAction";
import {
  getMaterialityAssessmentDetails,
  getMaterialityAssessmentList,
} from "actions/phase-3/materialityAssessment/materialityAssessmentAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useMaterialityListAPIs = (keyword = "", limit = 5, page = 0) => {
  const { entity_id } = useSelector((state) => state.user);

  const { materialityAssessmentList, totalMaterialityAssessments } =
    useSelector((state) => state?.materialityAssessment);
  const totalPages = materialityAssessmentList
    ? Math.ceil(totalMaterialityAssessments / limit)
    : 0;

  const dispatch = useDispatch();

  const fetchMaterialityList = async () => {
    const data = await dispatch(
      getMaterialityAssessmentList(
        entity_id,
        limit,
        (page - 1) * limit,
        keyword
      )
    );
    return data;
  };

  const fetchMaterialityAssessmentDetails = async (
    materiality_assessment_id
  ) => {
    const data = await dispatch(
      getMaterialityAssessmentDetails(materiality_assessment_id)
    );
    return data;
  };

  useEffect(() => {
    fetchMaterialityList(dispatch, keyword, limit, page);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, page]);

  useEffect(() => {
    dispatch(getAllFrameWorkList());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, page]);

  return {
    // states & data
    materialityAssessmentList,
    totalMaterialityAssessments,
    totalPages,
    // funcs
    fetchMaterialityList,
    fetchMaterialityAssessmentDetails,
  };
};
