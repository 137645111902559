import io from "socket.io-client";

let socket;

// connecting to socket io server from backend

// socket connection for general chat
const connectSocket = (user_id, chat_id) => {
  if (user_id && chat_id) {
    // ---------- localhost version ----------
    // socket = io("http://localhost:8610", {
    //   query: `user_id=${user_id}&chat_id=${chat_id}&is_company=False&isin=""&company_name=""`,
    //   withCredentials: true,
    // });

    socket = io("https://chat-esgwise.test.imgrows.app", {
      query: `user_id=${user_id}&chat_id=${chat_id}&is_company=False&isin=""&company_name=""`,
      withCredentials: true,
    });
  }
};

export { socket, connectSocket };
