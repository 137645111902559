import apiBolerplate from "utils/_helpers/apiBolerplate";
import {
  SET_MATERIALITY_ASSESSMENT_LIST,
  SET_MATERIALITY_ASSESSMENT_DETAILS,
  SET_ORIGINAL_MATERIALITY_ASSESSMENT_DETAILS,
  SET_MATERIALITY_MAIN_TOPIC,
  SET_MATERIALITY_TOPIC,
  SET_SELECTED_MATERIALITY_TOPIC,
  SET_TOPIC_LIMIT,
  SET_MATERIALITY_ASSESSMENT_YEAR_LIST,
  SET_MATERIALITY_ASSIGNED_USERS,
  SET_MATERIALITY_TOPIC_ANALYSIS,
} from "constants/phase-3/materialityAssessment/matAssessmentConst";

// ----------------- Payloads -----------------
export function setMaterialityAssessmentList(payload) {
  return {
    type: SET_MATERIALITY_ASSESSMENT_LIST,
    payload: payload,
  };
}

export function setMaterialityAssessmentDetails(payload) {
  return {
    type: SET_MATERIALITY_ASSESSMENT_DETAILS,
    payload: payload,
  };
}

export function setOriginalMaterialityDetails(payload) {
  return {
    type: SET_ORIGINAL_MATERIALITY_ASSESSMENT_DETAILS,
    payload: payload,
  };
}

export function setMaterialityMainTopic(payload) {
  return {
    type: SET_MATERIALITY_MAIN_TOPIC,
    payload: payload,
  };
}

export function setMaterialityTopics(payload) {
  return {
    type: SET_MATERIALITY_TOPIC,
    payload: payload,
  };
}

export function setSelectedMaterialityTopics(payload) {
  return {
    type: SET_SELECTED_MATERIALITY_TOPIC,
    payload: payload,
  };
}

export function setTopicLimit(payload) {
  return {
    type: SET_TOPIC_LIMIT,
    payload: payload,
  };
}

export function setMaterialityAssessmentsYearList(payload) {
  return {
    type: SET_MATERIALITY_ASSESSMENT_YEAR_LIST,
    payload: payload,
  };
}

export function setAssignedMaterialityUsers(payload) {
  return {
    type: SET_MATERIALITY_ASSIGNED_USERS,
    payload: payload,
  };
}

export function setMaterialitytopicAnalysis(payload) {
  return {
    type: SET_MATERIALITY_TOPIC_ANALYSIS,
    payload: payload,
  };
}

// ----------------- Actions -----------------

export function getMaterialityAssessmentList(
  entity_id,
  limit,
  starting_after,
  keyword
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/materiality_assessment/get_materiality_assessments",
      bodyData: { entity_id, limit, starting_after, keyword },
      callback: (data) => {
        dispatch(
          setMaterialityAssessmentList({
            result: data?.result,
            total: data?.total,
          })
        );
        dispatch(setMaterialityAssessmentDetails({}));
        dispatch(setOriginalMaterialityDetails({}));
        dispatch(setMaterialityAssessmentsYearList([]));
      },
    });

    return data; // Return the data for further use
  };
}

export function createMaterialityAssessment(basicDetail) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/materiality_assessment/create_materiality_assessment",
      bodyData: { ...basicDetail },
      callback: (data) => {
        // final actions
      },
    });

    return data; // Return the data for further use
  };
}

export function getMaterialityAssessmentDetails(materiality_assessment_id) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/materiality_assessment/get_materiality_assessment_details",
      bodyData: { materiality_assessment_id },
      callback: (data) => {
        // final actions
        dispatch(setMaterialityAssessmentDetails(data?.result));
        dispatch(setOriginalMaterialityDetails(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function updateMaterialityAssessment(basicDetail) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/materiality_assessment/update_materiality_assessments",
      bodyData: { ...basicDetail, materiality_assessment_id: basicDetail?._id },
      callback: (data) => {
        // final actions
      },
    });

    return data; // Return the data for further use
  };
}

export function getMaterialityMainTopic(framework_type) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/materiality_assessment/get_main_topics",
      bodyData: { framework_type: framework_type === "GRI" ? "GRI" : "ALL" },
      callback: (data) => {
        dispatch(setMaterialityMainTopic(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function getMaterialityTopic(
  materiality_assessment_id,
  framework_id,
  filter,
  starting_after,
  limit
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/materiality_assessment/get_topics",
      bodyData: {
        materiality_assessment_id,
        framework_id,
        filter,
        starting_after,
        limit,
      },
      callback: (data) => {
        dispatch(
          setMaterialityTopics({ result: data?.result, total: data?.total })
        );
        dispatch(
          getSelectedMaterialityTopic(materiality_assessment_id, framework_id)
        );
      },
    });

    return data; // Return the data for further use
  };
}

export function selectMaterialityTopic(
  materiality_assessment_id,
  topic_id,
  framework_id
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/materiality_assessment/select_topic",
      bodyData: {
        materiality_assessment_id,
        topic_id,
        framework_id,
      },
      callback: (data) => {
        // dispatch(setMaterialityTopics(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function selectAllMaterialityTopic(
  materiality_assessment_id,
  framework_id
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/materiality_assessment/select_all_topics",
      bodyData: {
        materiality_assessment_id,
        framework_id,
      },
      callback: (data) => {
        // dispatch(setMaterialityTopics(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function getSelectedMaterialityTopic(
  materiality_assessment_id,
  framework_id
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/materiality_assessment/get_selected_topics",
      bodyData: {
        materiality_assessment_id,
        framework_id,
      },
      callback: (data) => {
        dispatch(setSelectedMaterialityTopics(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function removeMaterialityTopic(materiality_topic_id) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/materiality_assessment/remove_topic",
      bodyData: {
        materiality_topic_id,
      },
      callback: (data) => {
        // dispatch(setMaterialityTopics(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function removeAllMaterialityTopic(materiality_assessment_id) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/materiality_assessment/remove_all_topics",
      bodyData: {
        materiality_assessment_id,
      },
      callback: (data) => {
        // dispatch(setMaterialityTopics(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function getMaterialityTopicYearBase(entity_id, year_of_assessment) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/materiality_assessment/get_materiality_assessments_with_year",
      bodyData: {
        entity_id,
        year_of_assessment,
      },
      callback: (data) => {
        dispatch(setMaterialityAssessmentsYearList(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function getAssignedMaterialityUsers(
  entity_id,
  materiality_assessment_id
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/assign_stakeholder/get_materiality_assessment_assigned_users",
      bodyData: {
        entity_id,
        materiality_assessment_id,
      },
      callback: (data) => {
        dispatch(setAssignedMaterialityUsers(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}

export function assignMaterialitySurvey(
  entity_id,
  materiality_assessment_id,
  user_ids
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/assign_stakeholder/assign_meteriality_assessment_topics",
      bodyData: {
        entity_id,
        materiality_assessment_id,
        user_ids,
      },
      callback: (data) => {
        dispatch(
          getAssignedMaterialityUsers(entity_id, materiality_assessment_id)
        );
      },
    });

    return data; // Return the data for further use
  };
}

export function addMaterialityCustomTopic(details) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/materiality_assessment/add_custom_topic",
      bodyData: { ...details },
      callback: (data) => {
        // final actions
        dispatch(
          getSelectedMaterialityTopic(
            details?.materiality_assessment_id,
            details?.framework_id
          )
        );
      },
    });

    return data; // Return the data for further use
  };
}

export function getMaterialityTopicsAnalysis(
  entity_id,
  materiality_assessment_id,
  framework_id
) {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/materiality_assessment/get_materiality_assessment_topics_for_analysis",
      bodyData: { entity_id, materiality_assessment_id, framework_id },
      callback: (data) => {
        // final actions
        dispatch(setMaterialitytopicAnalysis(data?.result));
      },
    });

    return data; // Return the data for further use
  };
}
