import {
  createMaterialityAssessment,
  setMaterialityAssessmentDetails,
  setOriginalMaterialityDetails,
  updateMaterialityAssessment,
} from "actions/phase-3/materialityAssessment/materialityAssessmentAction";
import { useDispatch, useSelector } from "react-redux";

export const useMaterialityBasicDetail = () => {
  const { entity_id } = useSelector((state) => state.user);

  const { materialityAssessmentDetails, originalMaterialityAssessmentDetails } =
    useSelector((state) => state?.materialityAssessment);

  const { frameWorkList } = useSelector((state) => state?.frameWorV3);

  const getFrameWorkType = (id) =>
    frameWorkList.find((f) => f?._id === id)?.framework_type || "";

  const dispatch = useDispatch();

  function areAllFieldsNotEmpty(obj) {
    // Check if the object has no properties
    if (Object.keys(obj).length === 0) {
      return false;
    }

    // Check if all properties are not null, undefined, or empty string
    return Object.values(obj).every(
      (value) => value !== null && value !== undefined && value !== ""
    );
  }

  let disableButton = areAllFieldsNotEmpty(materialityAssessmentDetails);

  const hasChanges = (original, updated) => {
    return JSON.stringify(original) !== JSON.stringify(updated);
  };

  const handleBasicDetailsAPi = async () => {
    let response;

    if (materialityAssessmentDetails._id) {
      // Check if there are changes before calling update API
      if (
        hasChanges(
          originalMaterialityAssessmentDetails,
          materialityAssessmentDetails
        )
      ) {
      
        dispatch(
          setMaterialityAssessmentDetails({
            ...materialityAssessmentDetails,
            entity_id,
            framework_type: getFrameWorkType(
              materialityAssessmentDetails?.framework_id
            ),
          })
        );
        dispatch(
          setOriginalMaterialityDetails({
            ...materialityAssessmentDetails,
            entity_id,
            framework_type: getFrameWorkType(
              materialityAssessmentDetails?.framework_id
            ),
          })
        );

        console.log("run");

        response = await dispatch(
          updateMaterialityAssessment({
            ...materialityAssessmentDetails,
            entity_id,
            framework_type: getFrameWorkType(
              materialityAssessmentDetails?.framework_id
            ),
          })
        );
      } else {
        response = { status: true };
      }
    } else {
      // Call create API if _id is not present
      response = await dispatch(
        createMaterialityAssessment({
          ...materialityAssessmentDetails,
          entity_id,
          framework_type: getFrameWorkType(
            materialityAssessmentDetails?.framework_id
          ),
        })
      );
    }

    return response;
  };

  // handleDisableCondition && handleBasicDetails(value);

  const handleMaterialityStep = (step) => {
    return step === 0 && handleBasicDetailsAPi();
  };

  return {
    handleMaterialityStep,
    disableButton,
    materialityAssessmentDetails,
    frameWorkList,
  };
};
