import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import GPTOldChat from "./ChatSections/GPTOldChat";
import GPTNewChat from "./ChatSections/GPTNewChat";
import GPTChatField from "./ChatSections/GPTCommon/GPTChatField";
import { useEffect, useState } from "react";
import {
  fetchAnswerFromMongo,
  updateAnswerForConvo,
  updateThinkingForConvo,
} from "actions/phase-3/gptWise/gptWiseAction";
import {
  resetChatTimer,
  stopChatTimer,
} from "actions/phase-3/chatTimers/chatTimersActions";
import { chatRefreshTimer } from "config";
import { connectSocket, socket } from "socket";

const GPTLayout = () => {
  const dispatch = useDispatch();
  const { user_id } = useSelector((state) => state.user);
  const { selectedChat } = useSelector((state) => state.gptWise);

  // card state
  const [selectedCard, setSelectedCard] = useState(null);

  // main socket handlers !Important (Make sure this useEffect always runs only once)

  // -------------------- handlers --------------------
  const handleSocketEvents = (socket, socketName) => {
    if (!socket || !user_id) {
      console.log(`Unable to connect to ${socketName} server`);
      return;
    } else {
      // Handle socket connection
      socket.on("connect", () => {
        console.log("recovered?", socket.recovered);
        console.log(`Connected to ${socketName} server`);
      });

      socket.on("disconnect", (disconnectData) => {
        console.log(`Disconnected from ${socketName}`, disconnectData);
      });

      // Handle "thinking" event
      socket.on("thinking", (thinkingData) => {
        // check if this is not company chat and current convo is correct for receiving
        const room_id =
          thinkingData?.room_id && thinkingData?.room_id.split("_")[1];

        console.log("Thinking now: ", thinkingData);

        if (selectedChat?.chat_id === room_id) {
          dispatch(
            updateThinkingForConvo(
              thinkingData?.question_id,
              thinkingData?.data
            )
          );
          const question_id = thinkingData?.question_id;
          // Reset the timer for this question ID
          dispatch(
            resetChatTimer(question_id, chatRefreshTimer, () =>
              fetchAnswerFromMongo(question_id)
            )
          );
        }
      });

      // Handle "final_result" event
      socket.on("final_result", (finalData) => {
        // check if this is not company chat and current convo is correct for receiving
        const room_id = finalData?.room_id && finalData?.room_id.split("_")[1];

        console.log("Response from Socket Server: ", finalData);

        if (selectedChat?.chat_id === room_id) {
          dispatch(updateAnswerForConvo(finalData?.question_id, finalData));

          const question_id = finalData?.question_id;

          // Stop the timer for this question ID
          dispatch(stopChatTimer(question_id));
        }
      });
    }
  };
  // ----------------------------------------------------

  // ----------- useEffects -----------
  // useEffect to make connections with sockets
  useEffect(() => {
    if (user_id && selectedChat?.chat_id) {
      connectSocket(user_id, selectedChat?.chat_id);
    }

    // Clean up on component unmount
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [user_id, selectedChat?.chat_id]);

  // useEffect to handle socket events
  useEffect(() => {
    handleSocketEvents(socket, "Socket Generic");

    return () => {
      // shutting off previous connections
      if (socket) {
        socket.off("connect");
        socket.off("disconnect");
        socket.off("thinking");
        socket.off("final_result");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChat?.chat_id]);
  // ----------------------------------

  return (
    <Box width="90%" p={2}>
      {/* Conversation Area */}
      <Box width="100%" height="68vh">
        {selectedChat?.type === "old" ? (
          <GPTOldChat selectedChat={selectedChat} />
        ) : (
          <GPTNewChat
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
          />
        )}
      </Box>

      {/* Chat field */}
      <GPTChatField selectedCard={selectedCard} />
    </Box>
  );
};
export default GPTLayout;
